import { ProcessProduct } from 'components/Admin';
function AdminAddGamePage() {
    return (
        <div>
            <ProcessProduct />
        </div>
    );
}

export default AdminAddGamePage;
