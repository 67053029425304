import { ProfileAccountSetting } from 'components';
function PersonalView() {
    return (
        <div>
            <ProfileAccountSetting />
        </div>
    );
}

export default PersonalView;
