import { ContactUs } from 'components';
function ContactUsPage() {
    return (
        <div>
            <ContactUs />
        </div>
    );
}

export default ContactUsPage;
