import { MyCart } from 'components';

function Home() {
    return (
        <div>
            <MyCart />
        </div>
    );
}

export default Home;
