import { Login } from 'components';

function AuthLogin() {
    return (
        <div>
            <Login />
        </div>
    );
}

export default AuthLogin;
