import React from 'react';
import { useLocation } from 'react-router-dom';
import Render from './render';
import './index.css';

function SignUp() {
    return (
        <div>
            <Render />
        </div>
    );
}

export default SignUp;
