import { Admin } from 'components/Admin';
function AdminPage() {
    return (
        <div>
            <Admin />
        </div>
    );
}

export default AdminPage;
