import { AdminModify } from 'components/Admin';

function AdminModifyPage() {
    return (
        <div>
            <AdminModify />
        </div>
    );
}

export default AdminModifyPage;
