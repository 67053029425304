import { ModifyDetails } from 'components/Admin';
function ModifyDetailsPage() {
    return (
        <div>
            <ModifyDetails />
        </div>
    );
}

export default ModifyDetailsPage;
